@import 'styles/variables';

.touchscreen {

  .header {
    @media screen and (min-width: 2560px) {
      min-height: calc(90px * #{$zoom});
    }
  }

  .header__wrapper {
    @media screen and (max-width: 1500px) {
      max-width: 100%;
    }
}

  .header__navigation-link {
    justify-content: center;
    width: 165px;
    height: 32px;
    padding: 6px 16px;
    color: $black;
    font-weight: 400;
    font-family: $secondary-font;
    text-transform: uppercase;
    background: $white;
    transition: all 1s ease-in-out;
    
    &:hover,
    &:focus {
      text-transform: uppercase;
      transition: all 1s ease-in-out;
    }
    
    &::after {
      content: none;
    }

    @media (min-width: 2560px) {
      width: calc(165px * #{$zoom});
      height: calc(32px * #{$zoom});
      padding: calc(6px * #{$zoom}) calc(16px * #{$zoom});
    }
    
    @media screen and (max-width: 1024px) {
      width: 135px;
      margin-right: 10px;
      font-size: 1.3vw;
    }
    
    &.active {
      color: white;
      text-shadow: 2px 2px 20px $black;
      background: $gold;
      filter: brightness(95%);
      transition: background 1s ease-in-out;
    }
    
    &:last-child {
      background: $gold;
      filter: brightness(100%);
    }
  }

  .header__force-refresh {
    display: block!important;
  }

  &__chanllenge {
    article {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
    }

    P {
      max-width: 728px;

      @media (min-width: 2560px) {
        max-width: calc(728px * #{$zoom});
      }
    }
  }

  &__chanllenge-qr-image {
    max-width: 168px;

    @media (min-width: 2560px) {
      max-width: calc(168px * #{$zoom});
    }

    figcaption {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -6px;
      padding: 16px 0;
      font-weight: 800;
      font-size: 20px;
      font-family: $secondary-font;
      text-transform: uppercase;
      background: linear-gradient(90deg, #00A3CF 0%, #5552A0 100%), linear-gradient(0deg, #396D98, #396D98);

      @media (min-width: 2560px) {
        font-size: calc(20px * #{$zoom});
      }
    }
  }

  &__challenge-results {
    max-width: 1140px;
    margin-top: 50px;
    padding: 0 100px 100px;
    background: linear-gradient(90deg, rgba(0, 163, 207, 0.2) 0%, rgba(85, 82, 160, 0.2) 100%);

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      margin-top: calc(50px * #{$zoom});
      padding: 0 calc(100px * #{$zoom}) calc(100px * #{$zoom});
    }

    @media screen and (max-width: 1024px) {
      padding: 0 50px 70px;
    }

    @media screen and (max-width: 640px) {
      padding: 0 20px 70px;
    }

    @media screen and (max-width: 475px) {
      padding: 0 ;
      background: none;
    }

    .tabs__container {
      @media screen and (max-width: 1024px) {
        padding-top: 50px;
      }
    }

    .tabs__tab {
      font-size: 32px;
      text-transform: none;

      @media (min-width: 2560px) {
        font-size: calc(32px * #{$zoom});
      }

      &::after {
        content: none;
      }
    }
  }

  .empty-placeholder {
    margin-top: 60px;

    @media (min-width: 2560px) {
      margin-top: calc(60px * #{$zoom});
      padding-top: calc(80px * #{$zoom});
    }

    h3 {
      color: $white;
      font-size: 40px;
      text-transform: uppercase;

      @media (min-width: 2560px) {
        font-size: calc(40px * #{$zoom});
      }
    }
  }
}