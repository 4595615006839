@import 'styles/variables';

%ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

%wrapper {
  width: 100%;
  max-width: calc(100% - 144px * 2);
  margin: 0 auto;
  padding: 0;

  @media only screen and (max-width: 1240px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (min-width: 2560px) {
    max-width: calc(100% - (144px * $zoom * 2));
  }

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }

  @media (max-width: 850), screen and (orientation: portrait) {
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

%first-level-heading {
  font-size: 58px;
  font-family: $secondary-font;
  line-height: 71px;

  @media (max-width: 767px) {
    font-size: 8.5vw;
    line-height: 9.1vw;
  }
}

%secondary-level-heading {
  font-size: 40px;
  font-family: $secondary-font;
  line-height: 49px;

  @media (max-width: 767px) {
    font-size: 6.5vw;
  }
}

@mixin customLinkAnimation($color, $lineHeight: 1px) {
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: $lineHeight;
    background: $color;
    transition: width 0.3s;
    content: '';
  }

  &:hover {
    &::after {
      left: 0;
      width: 100%;
    }
  }
}

%button-white {
  display: block;
  width: 242px;
  height: 58px;
  margin: 50px auto;
  padding: 0;
  color: $black;
  font-weight: normal;
  font-size: 14px;
  font-family: $secondary-font;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  background: $white;
  border: 2px solid $black;
  transition: all 0.3s linear;

  &:hover {
    color: $white;
    text-decoration: none;
    background: $black;
  }

  @media (max-width: 415px) {
    max-width: 100%;
  }
}

%button-colored {
  display: block;
  width: 280px;
  height: 56px;
  margin: 50px auto;
  padding: 0;
  color: $black;
  font-weight: normal;
  font-size: 16px;
  font-family: $secondary-font;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  background: $gold;
  border: 2px solid $gold;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    color: black;
    background: white;
    border: 2px solid $gold;
  }

  @media (max-width: 415px) {
    max-width: 100%;
  }
}

//*************************************
// 3D Scanner Demo

%gold-label {
  padding-bottom: 30px;
  color: $gold;
  font-weight: 900;
  font-size: 16px;
  font-family: $secondary-font;
  font-style: normal;
  line-height: 80%;
  text-transform: uppercase;
}

%anchor-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  padding: 10px 25px;
  color: $white;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  white-space: nowrap;
  text-decoration: none;
  background-color: $black;
  border: 1px solid $black;
  border-radius: 100px;
  cursor: pointer;

  @media screen and (min-width: 2560px) {
    max-width: calc(140px * #{$zoom});
    padding: calc(10px * #{$zoom}) calc(25px * #{$zoom});
    font-size: calc(16px * #{$zoom});
  }

  &:hover {
    color: $black;
    background-color: $white;
    border: 1px solid $black;
  }
}

%header-level-2 {
  color: $black;
  font-weight: 900;
  font-size: 42px;
  font-family: $secondary-font;
  font-style: normal;
  line-height: 80%;
  text-align: center;
  text-transform: uppercase;

  @media screen and (min-width: 2560px) {
    font-size: calc(42px * #{$zoom});
  }

  @media screen and (max-width: 475px) {
    font-size: 28px;
  }
}

%pre-heading {
  padding-bottom: 30px;
  color: $gold;
  font-weight: 900;
  font-size: 16px;
  font-family: $secondary-font;
  font-style: normal;
  line-height: 110%;
  text-align: center;
  text-transform: uppercase;

  @media screen and (min-width: 2560px) {
    padding-bottom: calc(30px * #{$zoom});
    font-size: calc(16px * #{$zoom});
  }
}