$cdnURL: 'https://www.softserveinc.com/cdn';
$zoom: 1.44;

$basic-font: 'OpenSans', Helvetica, Arial, sans-serif;
$basic-font--bold: 'OpenSans-Bold', Helvetica, Arial, sans-serif;
$basic-font--extrabold: 'OpenSans-Extrabold', Helvetica, Arial, sans-serif;
$secondary-font: 'ProximaNova-Black', sans-serif;
$secondary-font--bold: 'ProximaNova-Bold', sans-serif;
$secondary-font--extrabold: 'ProximaNova-ExtraBold', sans-serif;

$white:#FFFFFF;
$black: #000000;
$background-color: #131313;
$background-second: #060724;
$footer-gray: #909095;
$light-blue: #89F6FB;
$gold: #EAB938;