@import 'styles/variables';
@import 'styles/extensions';


// slider extensions

%slider-heading {
    padding-bottom: 60px;
    font-weight: 900;
    font-size: 42px;
    font-family: $secondary-font;
    font-style: normal;
    line-height: 80%;
    text-align: left;
    text-transform: uppercase;

    @media screen and (max-width: 1440px) {
        padding-bottom: 30px;
        font-size: 40px;
    }

    @media screen and (min-width: 2560px) {
        font-size: calc(42px * #{$zoom});
    }
}

.carousel {
    max-height: calc(100vh + 10px);
    background-color: $white;

    @media screen and (max-height: 820px) {
        max-height: 100%;
    }

    @media screen and (max-width: 475px) {
        max-height: fit-content;
    }

    &_shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.3253676470588235) 50%);

        @media screen and (max-width: 769px) {
            display: none;
        }
    }

    &__description {
        position: relative;
        top: calc(8% + 45px);
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 527px;
        margin-left: 0;
        color: $white;
        text-align: start;

        @media screen and (min-width: 2560px) {
            max-width: calc(527px * #{$zoom});
        }

        @media screen and (max-width: 1440px) {
            top: 8%;
            left: 0;
            margin-left: 0;
        }

        @media (max-width: 1024), screen and (orientation: portrait) {
            top: 15%;
            height: 100%;
        }

        @media screen and (max-width: 769px) {
            max-width: 100%;
            color: $black;
        }

        @media screen and (max-width: 475px) {
            top: unset;
            display: flex;
            justify-content: center;
        }

        &-label {
            @extend %pre-heading;
            font-weight: 900 !important;

            @media screen and (max-width: 769px) {
                display: none;
            }
        }

        h3 {
            @extend %slider-heading;
            font-size: 44px;

            @media screen and (min-width: 2560px) {
                font-size: calc(44px * #{$zoom});
            }

            @media screen and (max-width: 769px) {
                display: none;
            }
        }

        p {
            font-weight: 400;
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            line-height: 22px;
            text-align: left !important;

            @media screen and (min-width: 2560px) {
                font-size: calc(16px * #{$zoom});
                line-height: calc(22px * #{$zoom});
            }

            @media screen and (max-width: 768px) {
                position: relative;
                bottom: 0;
                margin-top: 20px;
                padding: 0;
            }
        }
    }

    &__slide-instance {
        width: 100%;
        max-width: 1096px;
        margin: 0 auto;
        padding-top: 80px;

        @media screen and (min-width: 2560px) {
            max-width: calc(1096px * #{$zoom});
        }

        @media screen and (max-width: 1440px) {
            padding-right: 40px;
            padding-left: 40px;
        }
        
        @media screen and (max-width: 1240px) {
            padding-top: 0px;
        }

        @media screen and (max-width: 475px) {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &__video-link {
        text-decoration: underline;
        cursor: pointer;
    }

    &-root {
        background: rgba($color: $black, $alpha: 0.3);
    }

    &-slider {
        &-wrapper {
            background: rgba(0,0,0,0.5);
        }

        .control-arrow {
            top: 40% !important;
            height: fit-content !important;
            background-color: rgba(0,0,0,0.2) !important;
            transform: translate(-50%, 0) !important;

            &:hover {
                z-index: 9999;
                background-color: $black;
            }
        }

        .control-prev.control-arrow,
        .control-next.control-arrow {
            padding: 65px;
            line-height: 1px;
            border-radius: 50%;
            @media screen and (max-width: 768px) {
                display: none !important;
            }
        }

        .control-prev.control-arrow {
            left: 12%;
        }

        .control-next.control-arrow {
            right: 5%;
        }
    }

    .slide {
        .legend {
            display: none !important;
        }

        .process-step {
            position: absolute;
            bottom: 15%;
            left: 50%;
            z-index: 99999;
            max-width: 345px !important;
            max-height: 200px !important;
        }
    }

    .control-dots {
        bottom: 10%;
        left: 10%;
        width: fit-content;
        height: fit-content;
        margin: 0;
        margin-left: 250px;
        color: #EEF1F6 !important;
        font-weight: 900;
        font-size: 16px;
        font-family: $secondary-font;

        @media screen and (max-width: 1440px) {
            bottom: 10%;
            margin-left: 0;
        }

        @media (max-width: 1200px), screen and (orientation: portrait) {
            top: 8%;
            right: 8%;
            left: unset;
        }

        @media screen and (max-width: 768px) {
            top: 20px;
            right: 20px;
            left: unset;
        }
    }
}

.custom-slide {
    position: relative;
    z-index: 294;
    height: calc(100vh + 10px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media screen and (max-width: 1440px) {
        display: flex;
        justify-content: center;
        // align-items: center;
    }

    @media screen and (max-width: 769px) {
        flex-direction: column;
        height: fit-content;
        background-color: $white;
        background-image: none !important;
    }

    @media screen and (max-width: 475px) {
        justify-content: flex-start;
        height: 100%;
    }

    .mobile-image-wrapper {
        position: relative;
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.3253676470588235) 50%);

        img {
            position: relative;
            z-index: -1;
            @media screen and (min-width: 769px) {
                display: none;
            }
        }

        .mobile-headings {
            position: absolute;
            bottom: 0vw;
            padding-left: 40px;
            color: $white;

            @media screen and (max-width: 475px) {
                padding-left: 20px;
            }

            h3 {
                @extend %slider-heading;
            }
            
            p {
                @extend %pre-heading;
                padding-bottom: 16px;
                text-align: left;

                @media screen and (max-width: 768px) {
                    max-width: 220px;
                }
            }

            @media screen and (min-width: 769px) {
                display: none;
            }
        }
    }
}

.use-case {

    &__control-btn {
        position: absolute;
        top: calc(50% - 90px);
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 65px;
        color: $white;
        background-color: rgba(255,255,255,0.04);
        border: none;
        border-radius: 100%;
        cursor: pointer;

        &:hover { 
            background-color: rgba(255,255,255,0.2);
        }

        &-right {
            right: 120px;
        }

        &-left {
            left: 120px;
        }

        @media screen and (min-width: 2560px) {
            padding: calc(65px * #{$zoom});
        }

        @media screen and (max-width: 1640px) {
            padding: 45px;

            &-right {
                right: 60px;
            }

            &-left {
                left: 60px;
            }
        }

        @media screen and (max-width: 1400px) {
            display: none;
        }

        img {
            position: absolute;
            width: 20px;
            height: 30px;

            @media screen and (min-width: 2560px) {
                width: calc(20px * #{$zoom});
                height: calc(30px * #{$zoom});
            }
        }
    }

    &__slide-index {
        @media screen and (min-width: 2560px) {
            font-size: calc(16px * #{$zoom}) !important;
        }
    }

    &__steps {
        position: absolute;
        bottom: 10%;
        left: 45%;
        z-index: 5;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1440px) {
            box-sizing: content-box;
            max-width: 527px;
            padding: 0 40px;
        }

        @media screen and (max-height: 900px) and (min-width: 1200px) {
            bottom: 7%;
        }

        @media screen and (max-height: 800px) and (min-width: 1200px) {
            left: 65%;
        }

        @media (max-width: 1024), screen and (orientation: portrait) {
            bottom: 10%;
            left: 10%;
        }

        @media screen and (max-width: 769px) {
            display: none;
        }

        &_dark img {
            background: $black;
        }

        &_white img {
            background: linear-gradient(180deg, #CBCBCB 0%, #E8E8E8 100%);
        }
    
        img {
            position: relative;
            width: 100% !important;
            max-width: 345px !important;
            max-height: 200px !important;
            object-fit: cover !important;
            border-radius: 10px;
            transition: background .5s ease;
            pointer-events: auto !important;
            
            @media screen and (max-height: 900px) and (min-width: 1200px){
                max-width: 241.5px !important;
                max-height: 140px !important;
            }

            @media screen and (max-width: 1440px) {
                width: 50% !important;
            }

            @media screen and (min-width: 2560px) {
                max-width: calc(345px * #{$zoom}) !important;
                max-height: calc(200px * #{$zoom}) !important;
            }
        }

        .step-image-container {
            img {
                @media screen and (max-height: 900px) and (min-width: 1200px){
                    max-width: 241.5px !important;
                    max-height: 140px !important;
                }
    
                @media screen and (max-width: 1440px) {
                    width: 100% !important;
                }
    
                @media screen and (min-width: 2560px) {
                    max-width: calc(345px * #{$zoom}) !important;
                    max-height: calc(200px * #{$zoom}) !important;
                }
            }
        }
    }

    &__steps-mobile {
        display: none;
        
        @media screen and (max-width: 769px) {
            position: relative;
            display: flex;
            flex-direction: row;
            gap: 20px;
            justify-content: space-between;
            margin-top: 20px;
            padding: 0 40px;

            @media screen and (max-width: 475px) {
                padding: 0 20px;
            }

            img {
                width: 100% !important;
                border-radius: 10px;
                pointer-events: auto !important;
            }
        }

        .step-image-container {
            max-width: 50%;
        }

        &_dark img {
            background: $black;
        }

        &_white img {
            background: linear-gradient(180deg, #CBCBCB 0%, #E8E8E8 100%);
        }
    }

    &__steps, &__steps-mobile {
        .image-clickable {
            position: relative;
            cursor: pointer;
    
            &:hover {
                filter: brightness(0.6);
                transition: all .5s ease;
            }
    
            &::before {
                content: url('../../../asset/resource/play-icon.svg');
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 4;
                background-position: center;
                transform: translate(-50%, -50%);
                opacity: 1;
            }
        }
    }
}