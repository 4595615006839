@import '~styles/variables';
@import '~styles/extensions';

.video-modal {
  padding: 48px 0 20px;

  &__modal {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 9999;
    display: flex;
    width: 100vw;
    height: calc(100vh + 10px);
    overflow: auto;
    background-color: #000000;

    &__content {
      position: absolute;
      top: 50%;
      left: 49.5%;
      width: 67.5vw;
      height: 38vw;
      transform: translate(-50%, -50%);

      iframe {
        width: 100%;
        height: 100%;
      }

      @media (max-width: 769px) {
        width: 90.5vw;
        height: 51vw;
      }
    }

    &__close {
      position: absolute;
      top: -10px;
      right: 0;
      left: auto;
      padding: 0;
      color: #777;
      font-size: 25px;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      transition: color 0.2s ease;

      &:hover {
        color: #fff;
      }
    }
  }
}
